import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Link from "@components/shared/Link/Link"
import "./PositionDetail.scss"
import { take } from "rxjs/operators"
import { ContentService } from "@utils/content-service"

const PositionDetail = props => {
  const [position, setPosition] = useState(null)

  useEffect(() => {
    const sub = ContentService.instance.positions.subscribe(positions => {
      setPosition(positions?.find(a => a.id === props.id))
    })
    if (ContentService.instance.positions.value?.length === 0) {
      ContentService.instance
        .getPositions()
        .pipe(take(1))
        .subscribe()
    }
    return () => {
      sub.unsubscribe()
    }
  }, [props.id])

  return (
    <section className="position-detail d-flex">
      {position ? (
        <Container className="my-5 py-5">
          <Row className="my-5 d-flex justify-content-center">
            <Col xs={11} sm={12} lg={10} xl={9}>
              <h5 className="text-capitalize">
                {position?.recruitingCategory}
              </h5>
            </Col>
            <Col xs={11} sm={12} lg={10} xl={9}>
              <h2 className="dot dot-blue">{position?.name}</h2>
            </Col>
            <Col xs={11} sm={12} lg={10} xl={9}>
              {position?.jobDescriptions?.jobDescription?.map((d, index) => (
                <Fragment key={index}>
                  <h4>{d.name}</h4>
                  <div
                    className="position-detail-list"
                    dangerouslySetInnerHTML={{ __html: d.value }}
                  ></div>
                </Fragment>
              ))}
            </Col>
            <Col xs={11} sm={12} lg={10} xl={9} className="my-3 py-3">
              <div className="m-auto">
                <Link
                  target="_blank"
                  to={`https://iti-gmbh.jobs.personio.de/job/${position?.id}?display=de#apply`}
                >
                  <Button color="dark" size="md">
                    Jetzt bewerben
                  </Button>
                </Link>

              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
    </section>
  )
}

export default PositionDetail
